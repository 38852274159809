import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/pgegenfurtner/Projekte/interness/atacama/atacama-packages/packages/web-core/src/templates/mdx/default.js";
import { graphql } from 'gatsby';
import { Container, Col, Row } from 'react-grid-system';
import TeamMembers from '@interness/web-core/src/components/modules/TeamMembers';
import OpeningHoursWidget from '@interness/web-core/src/components/modules/Widgets/OpeningHoursWidget';
import { Box } from '@chakra-ui/react';
import { RiTeamFill } from 'react-icons/ri';
export const query = graphql`
  query {
    image: directusMediaCollection(name: {eq: "ueber-uns"}) {
      name
      images: media {
        sort
        file {
          localFile {
            name
            childImageSharp {
              fluid(quality: 95) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Wrapper = makeShortcode("Wrapper");
const Heading = makeShortcode("Heading");
const Img = makeShortcode("Img");
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Wrapper mdxType="Wrapper">
      <hr></hr>
      <Heading icon={<RiTeamFill mdxType="RiTeamFill" />} mdxType="Heading">Über uns</Heading>
      <Container fluid mdxType="Container">
        <Row mdxType="Row">
          <Col md={4} mdxType="Col">
            <Img fluid={props.data.image.images[0].file.localFile.childImageSharp.fluid} mdxType="Img" />
            <hr></hr>
          </Col>
          <Col md={8} mdxType="Col">
            <p><strong parentName="p">{`STAUSS UHREN & SCHMUCK`}</strong>{` ist ein Haus mit einer langen Tradition in Rottweil und steht für präzise Arbeit im Uhrmacherhandwerk.`}</p>
            <p>{`Das hochwertige Uhrensortiment namhafter Marken wird durch aktuelle Schmuckkollektionen der angesagtesten Schmuckdesigner aus dem In- und Ausland ergänzt.`}</p>
            <p>{`Der Name STAUSS steht für Handwerksqualität auf höchstem Niveau, Top-Marken im Bereich Uhren und Schmuck sowie einen hervorragenden Service.`}</p>
            <p>{`Wir freuen uns über Ihren Besuch – im Internet und gerne auch persönlich in Rottweil.`}</p>
            <hr></hr>
  <Box maxW='280px' sx={{
              h4: {
                fontWeight: 'bold'
              }
            }} mdxType="Box">
    <OpeningHoursWidget lang={'de'} widget={{
                sort: 0,
                widget_name: 'openingHours',
                position: 'page',
                translations: [{
                  language: 'de',
                  header: 'Öffnungszeiten'
                }]
              }} mdxType="OpeningHoursWidget" />
  </Box>
          </Col>
        </Row>
      </Container>
      <hr></hr>
      <h2 {...{
        "id": "ihre-ansprechpartner"
      }}>{`Ihre Ansprechpartner`}</h2>
      <TeamMembers layout={'grid'} mdxType="TeamMembers" />
    </Wrapper>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      